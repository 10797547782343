import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import * as actions from '../../../redux/action';
import { useForm, Controller } from 'react-hook-form';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import { useHistory } from 'react-router-dom';
import {
  Button,
  Card,
  CardContent,
  Autocomplete,
  TextField,
} from '@mui/material';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import _ from 'lodash';
import dayjs from 'dayjs';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import ProductServe from '../../../components/Forms/FormProductArray';
import { ViewTitle } from 'components/ViewTitle';
import { BackButton } from 'components/Button';
import Sumprice from 'components/Card/Sumprice';
import SumAllPrice from 'components/Card/SumAllPrice';
import SumDiscount from 'components/Card/SumDiscount';

function NewRE({ title, subtitle }) {
  dayjs.extend(buddhistEra);
  const dispatch = useDispatch();
  const history = useHistory();
  const receipt = useSelector((state) => state.receipt);
  const customer = useSelector((state) => state.customer);
  const product = useSelector((state) => state.product);
  const { handleSubmit, control, setValue, watch } = useForm({});
  const [findName, setFindName] = useState('');
  const [page, setPage] = React.useState(1);
  const [size, setSize] = React.useState('');
  const [date, setDate] = React.useState(new Date());
  const [reload, setReload] = useState(false);
  const [productList, setProductList] = useState([]);
  const [customerData, setCustomerData] = useState();
  const [productVatUni, setProductVatUni] = useState(false);
  const [productVat, setProductVat] = useState(false);
  const [basicVat, setBasicVat] = React.useState(false);
  const [vatNumber, setVatNumber] = useState('');
  useEffect(() => {
    dispatch(actions.getAllCustomer({ findName, page, size }));
    dispatch(actions.getAllProduct());

    return () => {};
  }, []);
  const handleCheckCustomer = (data) => {
    const customers = _.find(customer.arr, { _id: data?._id });
    setCustomerData(customers);
    setValue('taxId', customers?.TaxId);
    setValue('tel', customers?.tel);
    setValue('address', customers?.address);
    setValue('status', customers?.status);

    // console.log("customer id", customers?._id);
  };
  useEffect(() => {
    setDate(customer?.date);
    setProductList(customer?.product);
    if (!customer.isloading) {
      setValue('productArray', customer?.product);
    }
    setValue('businessname', customer?.businessname);
    setValue('taxId', customer?.TaxId);
    setValue('tel', customer?.tel);
    setValue('name', customer?.name);
    setValue('address', customer?.address);
    setValue('payment', customer?.payment);
    setValue('credit', customer?.credit);
    setValue('note', customer?.note);
    setValue('status', customer?.status);
    setValue('running_number', customer?.running_number);
    setVatNumber(customer?.vatNumber);
    setProductVat(customer?.statusTax);
    setProductVatUni(customer?.statusTaxUni);

    return () => {};
  }, [customer]);
  const onSubmitQuotation = async (data) => {
    if (productList?.length === 0) alert('กรุณาเพิ่มสินค้า');
    else {
      try {
        const ProcessData = await {
          date,
          ...data,
          prefix: 'RE' + dayjs(date).format(`BB`) + '-',
          customer: customerData?._id,
          product: data?.productArray,
          allPrice: SumAllPrice(),
          vatNumber: vatNumber,
          statusTaxUni: productVatUni,
          statusTax: productVat,
        };
        // console.log("dataSubmit", ProcessData);
        await dispatch(actions.addReceipt(ProcessData));
        await dispatch(actions.getAllReceipt({ findName, page, size }));
        history.push('/sale/receipt');
      } catch (error) {}
    }
    console.log('vatNumber', vatNumber);
  };
  const Price = () => {
    if (productList?.length === 0) {
      return 0;
    }
    return Sumprice(productList) - SumDiscount(productList);
  };
  const tax = () => {
    if (!productVat) {
      return 0;
    }
    const price = SumAllPrice(productList);
    const tax = price * 0.03;
    return tax;
  };
  const taxBasicVat = () => {
    return Price() * 0.07;
  };

  const taxUniversity = () => {
    if (!productVatUni) {
      return 0;
    }
    const priceUniversity = SumAllPrice(productList);
    const taxUniversity = priceUniversity * 0.01;
    return taxUniversity;
  };

  const PriceVatNum = () => {
    return Price() * (vatNumber / 100) || 0;
  };
  console.log('PriceVatNum', PriceVatNum());

  // const priceTax = (status) => SumAllPrice(productList) + taxBasicVat();
  const priceTax = () =>
    SumAllPrice(productList) - PriceVatNum() + taxBasicVat();
  if (customer.isloading && product.isloading) {
    return <>error</>;
  }

  const priceProductVat = () =>
    SumAllPrice(productList) + taxBasicVat() - tax();
  // const priceTax = () => SumAllPrice(productList);
  const priceTaxUni = () =>
    SumAllPrice(productList) + taxBasicVat() - taxUniversity();

  const sumPrice = () => {
    if (productList?.length === 0) {
      return 0;
    }
    const sumP = _.sumBy(productList, (each) => each?.price * each?.quantity);
    return sumP;
  };
  const sumDiscount = () => {
    if (productList?.length === 0) {
      return 0;
    }
    const sumP = _.sumBy(productList, (each) => parseInt(each?.discount));
    return sumP;
  };

  const sumAllPrice = (productList) => {
    if (productList?.length === 0) {
      return 0;
    }
    return sumPrice() - sumDiscount();
  };

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  if (customer.isloading && product.isloading) {
    return <>error</>;
  }
  if (!customer.isloading && !product.isloading) {
    return (
      <div className="min-h-screen">
        <div>
          {renderTitle()}
          <div className="py-2">
            <BackButton />
          </div>
        </div>
        <div>
          <div>
            <div className="flex">
              <Card>
                <div className="p-2">
                  <h1 className="pb-4"> ข้อมูลลูกค้า</h1>
                  <div className="w-2/3">
                    <Autocomplete
                      disablePortal
                      id="free-solo-demo"
                      freeSolo
                      options={customer?.arr || []}
                      getOptionLabel={(option) => option.businessname}
                      onChange={(e, newValue) => handleCheckCustomer(newValue)}
                      renderInput={(params) => (
                        <TextField {...params} label="ชื่อลูกค้า" required />
                      )}
                    />{' '}
                  </div>{' '}
                  <Controller
                    name="taxId"
                    control={control}
                    defaultValue={''}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        value={field.value}
                        label="หมายเลขผู้เสียภาษี"
                        variant="outlined"
                        margin="normal"
                        fullWidth
                      />
                    )}
                  />
                  <Controller
                    name="tel"
                    defaultValue=""
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        value={field.value}
                        id="outlined-basic"
                        label="เบอร์โทร"
                        variant="outlined"
                        fullWidth
                      />
                    )}
                  />
                  <Controller
                    name="address"
                    defaultValue=""
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        value={field.value}
                        id="outlined-multiline-basic"
                        label="ที่อยู่"
                        variant="outlined"
                        fullWidth
                        multiline
                        margin="normal"
                        rows={4}
                      />
                    )}
                  />
                </div>
              </Card>

              <div className="p-2 w-full">
                <Card>
                  <CardContent>
                    {' '}
                    <form onSubmit={handleSubmit(onSubmitQuotation)}>
                      <div className="flex justify-between">
                        <div>
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                              label="วันที่"
                              value={date}
                              onChange={(newValue) => {
                                setDate(newValue);
                              }}
                              renderInput={(params) => (
                                <TextField {...params} />
                              )}
                            />
                          </LocalizationProvider>{' '}
                        </div>
                        <Button
                          variant="contained"
                          type="submit"
                          // onClick={onSubmitQuotation}
                        >
                          บันทึก
                        </Button>
                      </div>
                      <Controller
                        name="sale"
                        control={control}
                        defaultValue={'-'}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            value={field.value}
                            label="พนักงานขาย"
                            variant="outlined"
                            margin="normal"
                            fullWidth
                          />
                        )}
                      />
                      <Controller
                        name="payment"
                        control={control}
                        defaultValue={'-'}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            value={field.value}
                            label="วิธีการชำระเงิน"
                            variant="outlined"
                            fullWidth
                          />
                        )}
                      />{' '}
                      <Controller
                        name="doc"
                        control={control}
                        defaultValue={'-'}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            value={field.value}
                            label="เอกสารอ้างอิง"
                            variant="outlined"
                            margin="normal"
                            fullWidth
                          />
                        )}
                      />
                    </form>
                  </CardContent>
                </Card>
              </div>
            </div>
          </div>
          <div>
            <div className="mt-2">
              <ProductServe
                setProductList={setProductList}
                setValue={setValue}
                control={control}
                watch={watch}
                statusHide={false}
                statusPickUp={false}
                statusUni={false}
              />
            </div>
            <Card>
              <CardContent>
                <div className="flex w-full ">
                  <div className="w-1/2">
                    {' '}
                    <Controller
                      name="note"
                      defaultValue=""
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          value={field.value}
                          id="outlined-multiline-basic"
                          label="หมายเหตุ"
                          variant="outlined"
                          fullWidth
                          multiline
                          margin="normal"
                          rows={4}
                        />
                      )}
                    />
                  </div>

                  <div className="flex justify-between  w-1/2 ml-2  ">
                    <div>
                      <div>รวมเงิน </div>
                      <div>ส่วนลดการค้า</div>
                      <div>เงินหลังหักส่วนลด</div>
                      <div>ภาษีมูลค่าเพิ่ม 7%</div>
                      <div className="flex pb-4">
                        <div>หัก ณ ที่จ่าย</div>
                        <div className="pl-2 ">
                          <TextField
                            sx={{
                              width: '40px',
                              '& .MuiInputBase-root': { height: '25px' },
                            }}
                            value={vatNumber}
                            onChange={(e) => {
                              setVatNumber(e.target.value);
                            }}
                          />
                        </div>
                        <div className="pl-2">%</div>
                      </div>
                      <div className="font-bold ">จำนวนเงินทั้งสิ้น </div>
                    </div>
                    <div>
                      <div>
                        <div>
                          {sumPrice()
                            ?.toFixed(2)
                            .replace(/\d(?=(\d{3})+\.)/g, '$&,')}{' '}
                        </div>
                        <div>
                          {sumDiscount()
                            ?.toFixed(2)
                            .replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                        </div>
                        <div>
                          {sumAllPrice()
                            ?.toFixed(2)
                            .replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                        </div>
                        <div>
                          {taxBasicVat()
                            ?.toFixed(2)
                            .replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                        </div>
                        <div className="flex pb-4">
                          {PriceVatNum()
                            ?.toFixed(2)
                            .replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                        </div>

                        <div className="font-bold ">
                          {priceTax(productList)
                            ?.toFixed(2)
                            .replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </CardContent>
            </Card>
          </div>
        </div>
      </div>
    );
  }
  return <>error</>;
}
export default NewRE;
