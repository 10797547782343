/* eslint-disable quotes */
/* eslint-disable import/no-unresolved */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as action from '../../../redux/action';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import DeleteIcon from '@mui/icons-material/Delete';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import PrintIcon from '@mui/icons-material/Print';
import { Card, CardContent } from '@mui/material';
import { TextField } from '@mui/material';
import Button from '@mui/material/Button';
import QtReport from '../../../components/Report/QtReport';
import QtReportCopy from '../../../components/Report/QTReportCopy';
import QtNovat from '../../../components/Report/QtNoVat';
import Loading from '../../../components/Loading';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import { ViewTitle } from 'components/ViewTitle';
import { useHistory } from 'react-router';
import Modal from '@mui/material/Modal';
import dayjs from 'dayjs';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import { selectSeries } from 'async';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};
function IndexQT({ title, subtitle }) {
  const dispatch = useDispatch();
  dayjs.extend(buddhistEra);
  const history = useHistory();
  const quotation = useSelector((state) => state.quotation);
  const information = useSelector((state) => state.information);
  const me = useSelector((state) => state.me);
  const [findName, setFindName] = useState('');
  const [size, setSize] = React.useState(5);
  const [page, setPage] = React.useState(1);
  const [checked, setChecked] = React.useState(true);
  const [selectQuotation, setSelectQuotation] = useState();

  // setModal;

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [open, setOpen] = React.useState(false);

  console.log('quotation ', quotation?.arr);

  useEffect(() => {
    dispatch(action.getAllQuotation({ name: findName, page, size }));
    dispatch(action.getAllInformation());
    return () => {};
  }, [findName, page, size]);
  const handleChange = (event) => {
    setChecked(event.target.checked);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
    // console.log("page ", page);
  };

  const handlePrintQtReport = (data) => {
    QtReport(selectQuotation, information, me, checked);
  };
  const handlePrintQtReportUni = (data) => {
    QtReportCopy(selectQuotation, information, me, checked);
    console.log('Select', selectQuotation);
  };
  const handlePrintQtNoVat = (data) => {
    QtNovat(selectQuotation, information, me, checked);
  };

  const handleChangeRowsPerPage = (event) => {
    setSize(event.target.value);
    setPage(1);
  };
  const DeleteProduct = async (id) => {
    const confirm = window.confirm('ยืนยันการลบข้อมูล');
    if (confirm) {
      try {
        await dispatch(action.deleteQuotation(id));
        await dispatch(action.getAllQuotation({ findName, page, size }));
      } catch (error) {
        console.error(error);
      }
    }
  };

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;
  const renderAddButton = () => (
    <Button
      variant="contained"
      onClick={() => history.push('/sale/createQuotation')}
    >
      เพิ่ม
    </Button>
  );

  const rendermodal = () => (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="p-8">
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              พิมพ์ใบเสนอราคา
              <br />
              <br />
              <Checkbox
                label="มีลายเซ็นต์"
                checked={checked}
                onChange={handleChange}
                inputProps={{ 'aria-label': 'controlled' }}
              />
              เพิ่มลายเซ็นอิเล็กทรอนิกส์
              <div className="text-center pt-8">
                <Button
                  size="large"
                  className="text-center"
                  variant="contained"
                  color="success"
                  onClick={() => {
                    console.log('print', selectQuotation);
                    if (selectQuotation?.vatNumber === 0) {
                      handlePrintQtNoVat(selectQuotation);
                    } else {
                      handlePrintQtReport();
                    }

                    handleClose();
                  }}
                >
                  พิมพ์
                </Button>
              </div>
            </Typography>
          </Box>
        </div>
      </Modal>
    </div>
  );

  if (quotation.isLoading) {
    return (
      <div>
        <Loading isLoading />
      </div>
    );
  }

  return (
    <div>
      {rendermodal()}
      <div className="flex justify-between">
        {renderTitle()}
        <div className="py-4">{renderAddButton()}</div>
      </div>
      <Card className="mb-2">
        <CardContent>
          <div>
            <div className="flex">
              <TextField
                id="outlined-basic"
                label="ค้นหา"
                variant="outlined"
                size="small"
                onChange={(e) => setFindName(e.target.value)}
                fullWidth
              />
            </div>
          </div>
        </CardContent>
      </Card>
      <Paper>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <p className="font-bold text-center ">เลขที่</p>
                </TableCell>
                <TableCell>
                  {' '}
                  <p className="font-bold text-center">วันที่</p>
                </TableCell>
                <TableCell>
                  {' '}
                  <p className="font-bold text-center">ชื่อลูกค้า</p>
                </TableCell>
                <TableCell>
                  {' '}
                  <p className="font-bold text-center">เบอร์โทร</p>
                </TableCell>
                <TableCell>
                  <p className="font-bold text-center">ดำเนินการ</p>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {quotation.arr?.map((quotations) => (
                <TableRow key={quotations?._id}>
                  <TableCell component="th" scope="row" align="center">
                    <Link to={`/sale/editquotation/${quotations?._id}`}>
                      {quotations?.prefix + quotations?.running_number}{' '}
                    </Link>
                  </TableCell>
                  {console.log('quotations._id ', quotations?._id)}
                  <TableCell align="center">
                    <Link to={`/sale/editquotation/${quotations?._id}`}>
                      {dayjs(quotations?.date).format('DD/MM/BB')}
                    </Link>{' '}
                  </TableCell>
                  <TableCell align="center">
                    <Link to={`/sale/editquotation/${quotations?._id}`}>
                      {quotations?.customer?.businessname}
                    </Link>{' '}
                  </TableCell>
                  <TableCell align="center">
                    {quotations?.customer?.tel}
                  </TableCell>
                  <TableCell align="center">
                    <Stack
                      spacing={1}
                      direction="row"
                      className="justify-center"
                    >
                      <div>
                        <div>
                          <Button
                            variant="contained"
                            color="success"
                            startIcon={<PrintIcon />}
                            onClick={() => {
                              handleOpen();
                              setSelectQuotation(quotations);
                            }}
                          >
                            พิมพ์
                          </Button>
                        </div>
                      </div>

                      {/* <Link to={`/sale/editquotation/${quotations?._id}`}>
                            <Button
                              variant="contained"
                              startIcon={<EditIcon />}
                            >
                              แก้ไข
                            </Button>
                          </Link> */}
                      <Button
                        color="error"
                        variant="contained"
                        startIcon={<DeleteIcon />}
                        onClick={() => {
                          DeleteProduct(quotations?._id);
                        }}
                      >
                        ลบ
                      </Button>
                    </Stack>
                  </TableCell>{' '}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10]}
          component="div"
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          count={quotation?.total || 0}
          rowsPerPage={size}
          page={page - 1}
        />
      </Paper>
    </div>
  );
}
export default IndexQT;
