import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Button } from '@mui/material';
import Save from '@mui/icons-material/Save';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import LayoutPageAdd from 'components/layout/LayoutPageAdd';
import * as action from '../../redux/action';
import Layout from '../../components/layout/Layout';
import FormCustomer from '../../components/Forms/FormCustomer';
import { ViewTitle } from 'components/ViewTitle';
import { BackButton } from 'components/Button';

function EditCustomer({ title, subtitle }) {
  const params = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const Customers = useSelector((state) => state.customer);

  const [productVatUni, setProductVatUni] = useState(false);
  const [productVat, setProductVat] = useState(false);
  useEffect(() => {
    dispatch(action.getOneCustomer(params.id));
  }, []);

  async function onSubmit(data) {
    console.log('Data', data);

    try {
      // const ProcessData = await {
      //   ...data,
      //   id: params.id,
      //   buName: data.buName,
      //   taxId: data.taxId,
      //   name: data.name,
      //   tel: data.tel,
      //   address: data.address,
      //   status: productVat,
      //   statusuni: productVatUni,
      // };
      await dispatch(
        action.putCustomer(
          params.id,
          data.buName,
          data.taxId,
          data.name,
          data.tel,
          data.address,
          data.status
        )
      );
      await dispatch(action.getAllCustomer({}));
      history.push('/customer');
    } catch (error) {}
    console.log('data', data);
  }
  console.log('ProductUni', productVatUni);
  console.log('Product', productVat);

  const { handleSubmit, control, watch, setValue } = useForm({
    mode: 'onChange',
    defaultValues: Customers,
  });

  useEffect(() => {
    setValue('buName', Customers?.businessname);
    setValue('taxId', Customers?.TaxId);
    setValue('tel', Customers?.tel);
    setValue('name', Customers?.name);
    setValue('address', Customers?.address);
    setValue('status', Customers?.status);
    setValue('statusuni', Customers?.statusuni);
    return () => {};
  }, [Customers]);
  console.log('Customers', Customers.statusuni);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  return (
    <div>
      {renderTitle()}
      <div className="py-2">
        <BackButton />
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormCustomer
          title="ข้อมูลลูกค้า"
          Controller={Controller}
          control={control}
          watch={watch}
          customer={Customers}
          // productVatUni={productVatUni}
          // setProductVatUni={setProductVatUni}
        />
        <div className="pt-2 pl-2 ">
          <Button variant="outlined" startIcon={<Save />} type="submit">
            บันทึก
          </Button>
        </div>
        {console.log('productVatUni on submit', productVatUni)}
      </form>
    </div>
  );
}
export default EditCustomer;
