/* eslint-disable arrow-body-style */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
// eslint-disable-next-line import/order
import * as action from '../../../redux/action';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import DeleteIcon from '@mui/icons-material/Delete';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import PrintIcon from '@mui/icons-material/Print';
import Checkbox from '@mui/material/Checkbox';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { Card, CardContent } from '@mui/material';
import { TextField } from '@mui/material';

import Button from '@mui/material/Button';
// eslint-disable-next-line import/order
import EmployeeSalaryReport2 from '../../../components/Report/emSalaryReport2';
// eslint-disable-next-line import/no-unresolved
import EmployeeSalaryReport from '../../../components/Report/emSalaryReport';
import SalaryReport from '../../../components/Report/salaryReport';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { useHistory } from 'react-router-dom';
import dayjs from 'dayjs';

import 'dayjs/locale/th';
import { ViewTitle } from 'components/ViewTitle';
import Loading from 'components/Loading';
import MonthPicker from 'components/Datepicker/MonthPicker';

function Salary({ title, subtitle, Controller, control }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const salarys = useSelector((state) => state.Salary);
  const information = useSelector((state) => state.information);
  const setting = useSelector((state) => state.setting);
  const [findName, setFindName] = useState('');
  const [page, setPage] = React.useState(1);
  const [size, setSize] = React.useState(10);
  const [date, setDate] = React.useState(new Date());
  console.log('setting', setting);

  const [checked, setChecked] = React.useState(true);
  const [selectSalary, setSelectSalary] = useState();

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [open, setOpen] = React.useState(false);
  const [note, setNote] = useState('');

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  console.log(date);

  const [endDate, setEndDate] = React.useState(
    dayjs().startOf('month').toDate()
  );

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const hadleAddButton = () => (
    <div className="flex justify-end py-2 ">
      <Button variant="contained" onClick={() => history.push('/salary/new')}>
        เพิ่ม
      </Button>
    </div>
  );

  useEffect(() => {
    dispatch(action.resetSaraly());

    return () => {};
  }, []);
  useEffect(() => {
    dispatch(
      action.getAllSalary({
        name: findName,
        page,
        size,
        date: date.toISOString(),
        endDate: dayjs(endDate).startOf('month').toISOString(),
        status: false,
        orderBy: 'user.employeeId',
      })
    );
    return () => {};
  }, [findName, page, size, date, endDate]);
  console.log('Saraly  in', salarys);

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
    // console.log("page ", page);
  };

  const handlePrintReport = (data) => {
    EmployeeSalaryReport(data, information, date);
  };

  const handlePrintReport2 = (data) => {
    console.log('Salary data', data, date);

    EmployeeSalaryReport2(data, information);
  };
  const handleSalaryReport = (data) => {
    SalaryReport(data, information, note, date);
    console.log('data', data);
  };

  const handleChangeRowsPerPage = (event) => {
    setSize(event.target.value);
    setPage(1);
  };

  const onchangeNote = (e) => {
    setNote(e.target.value);
  };
  console.log('note', note);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const revenue = (datasalary, dataOT, dataExtra, dataAllowance) => {
    return datasalary + dataOT + dataExtra + dataAllowance;
  };
  const expenses = (sumSSO, sumEtc) => {
    return sumSSO + sumEtc;
  };
  const DeleteProduct = async (id) => {
    const confirm = window.confirm('ยืนยันการลบข้อมูล');
    if (confirm) {
      try {
        await dispatch(action.deleteSalary(id));
        await dispatch(
          action.getAllSalary({ findName, page, size, date, endDate })
        );
      } catch (error) {
        console.error(error);
      }
    }
  };

  const rendermodal = () => (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="p-8">
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              พิมพ์ใบเงินเดือน
              <br />
              <br />
              {/* <Card>
                <CardContent>
                  <div className="flex w-full ">
                    <div className="w-1/2">
                      {' '}
                      <Controller
                        name="note"
                        defaultValue=""
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            value={field.value}
                            id="outlined-multiline-basic"
                            label="หมายเหตุ"
                            variant="outlined"
                            fullWidth
                            multiline
                            margin="normal"
                            rows={4}
                          />
                        )}
                      />
                    </div>
                  </div>
                </CardContent>
              </Card> */}
              <div>
                {' '}
                <h1 className="font-bold  pt-4 ">หมายเหตุ</h1>
                <div className="text-l pt-2">
                  <TextField
                    id="outlined-basic"
                    fullWidth
                    onChange={(e) => {
                      onchangeNote(e);
                    }}
                    label="หมายเหตุ"
                    name="note"
                    defaultValue=""
                    variant="outlined"
                  />
                </div>
              </div>
              <div className="text-center pt-8">
                <Button
                  size="large"
                  className="text-center"
                  variant="contained"
                  color="success"
                  onClick={() => {
                    console.log('print', selectSalary);

                    handleSalaryReport(salarys);
                    handleClose();
                  }}
                >
                  พิมพ์
                </Button>
              </div>
            </Typography>
          </Box>
        </div>
      </Modal>
    </div>
  );

  if (salarys?.isloading) {
    return (
      <div>
        <Loading isloading />
      </div>
    );
  }
  if (!salarys?.isloading) {
    return (
      <div>
        <div>
          {renderTitle()}
          {rendermodal()}
          {hadleAddButton()}
          <Card className="mb-2">
            <CardContent>
              <div>
                <div className="flex justify-end gap-2 ">
                  <div>
                    <TextField
                      size="small"
                      label="ค้นหาพนักงาน"
                      onChange={(e) => setFindName(e.target.value)}
                    />
                  </div>
                  <div>
                    {' '}
                    <MonthPicker value={date} setValue={setDate} />
                    {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        label="เริ่มต้น"
                        views={['year', 'month']}
                        value={date}
                        onChange={(newValue) => {
                          setDate(newValue);
                        }}
                        renderInput={(params) => (
                          <TextField {...params} size="small" />
                        )}
                      />
                    </LocalizationProvider>{' '}
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        label="สิ้นสุด"
                        views={['year', 'month']}
                        value={endDate}
                        onChange={(newValue) => {
                          setEndDate(newValue);
                        }}
                        renderInput={(params) => (
                          <TextField {...params} size="small" />
                        )}
                      />
                    </LocalizationProvider>{' '} */}
                  </div>
                  <div>
                    <Button
                      variant="contained"
                      color="success"
                      // onClick={() => handleSalaryReport(salarys)}
                      onClick={() => {
                        handleOpen();
                        setSelectSalary(salarys);
                      }}
                    >
                      พิมพ์
                    </Button>
                  </div>
                </div>
              </div>
            </CardContent>
          </Card>
          <Paper>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <p className="font-bold">รหัสพนักงาน</p>
                    </TableCell>
                    <TableCell>
                      <p className="font-bold">เดือน/ปี</p>
                    </TableCell>
                    <TableCell>
                      <p className="font-bold">ชื่อ-สกุล</p>
                    </TableCell>
                    <TableCell>
                      <p className="font-bold">รวมรายรับ</p>
                    </TableCell>
                    <TableCell>
                      <p className="font-bold">รวมหัก</p>
                    </TableCell>
                    <TableCell>
                      <p className="font-bold">รับสุทธิ</p>
                    </TableCell>
                    <TableCell>
                      <p className="font-bold">วันที่จ่ายเงิน</p>
                    </TableCell>
                    <TableCell />
                  </TableRow>
                </TableHead>
                {_?.isEmpty(salarys?.arr) ? (
                  <TableBody>
                    <TableRow>
                      <TableCell colSpan={8} align="center">
                        ไม่มีรายการ
                      </TableCell>
                    </TableRow>
                  </TableBody>
                ) : (
                  <TableBody>
                    {salarys?.arr?.map((salary) => (
                      <TableRow key={salary?._id}>
                        <TableCell component="th" scope="row">
                          {salary?.user?.employeeId || '-'}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <Link to={`/salary/edit/${salary?._id}`}>
                            {dayjs(salary?.date).format('MMMM-BBBB')}
                          </Link>
                        </TableCell>
                        <TableCell>
                          <Link to={`/salary/edit/${salary?._id}`}>
                            {salary?.user?.name} {salary?.user?.surname}
                          </Link>
                        </TableCell>
                        <TableCell>
                          {revenue(
                            salary?.salary,
                            salary?.ot,
                            salary?.extra,
                            salary?.allowance
                          )
                            .toFixed(2)
                            .replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                        </TableCell>
                        <TableCell>
                          {expenses(salary?.sumSSO, salary?.sumEtc)
                            .toFixed(2)
                            .replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                        </TableCell>
                        <TableCell>
                          {(
                            revenue(
                              salary?.salary,
                              salary?.ot,
                              salary?.extra,
                              salary?.allowance
                            ) - expenses(salary?.sumSSO, salary?.sumEtc)
                          )
                            .toFixed(2)
                            .replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                        </TableCell>
                        <TableCell>
                          {dayjs(salary?.datePay).format('DD/MM/BB')}
                        </TableCell>
                        <TableCell align="right">
                          <Stack
                            spacing={1}
                            direction="row"
                            className="justify-end"
                          >
                            {salary?.file?.url ? (
                              <a
                                href={salary?.file?.url}
                                without
                                rel="noopener noreferrer"
                                target="_blank"
                              >
                                <Button
                                  variant="contained"
                                  size="small"
                                  startIcon={<AttachFileIcon size="small" />}
                                >
                                  ไฟล์สลิป
                                </Button>
                              </a>
                            ) : (
                              <Button
                                variant="contained"
                                disabled
                                size="small"
                                startIcon={<AttachFileIcon />}
                              >
                                ไฟล์สลิปเงินเดือน
                              </Button>
                            )}
                            {setting?.arr?.salaryslip?.typeOne === true ? (
                              <div>
                                <Button
                                  variant="contained"
                                  color="success"
                                  startIcon={<PrintIcon size="small" />}
                                  onClick={() => {
                                    handlePrintReport(salary);
                                  }}
                                >
                                  พิมพ์
                                </Button>
                              </div>
                            ) : (
                              <div>
                                <Button
                                  variant="contained"
                                  color="success"
                                  startIcon={<PrintIcon size="small" />}
                                  onClick={() => {
                                    // console.log("print", salary);
                                    handlePrintReport2(salary);
                                  }}
                                >
                                  พิมพ์
                                </Button>
                              </div>
                            )}

                            {}
                            <Button
                              color="error"
                              variant="contained"
                              size="small"
                              startIcon={<DeleteIcon size="small" />}
                              onClick={() => {
                                DeleteProduct(salary?._id);
                              }}
                            >
                              ลบ
                            </Button>
                          </Stack>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                )}
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 15, 30, 50, 100]}
              component="div"
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              count={salarys?.total || 0}
              rowsPerPage={size}
              page={page - 1}
            />
          </Paper>
        </div>
      </div>
    );
  }
  return <h1>404</h1>;
}
export default Salary;
