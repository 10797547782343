import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as action from '../../../redux/action';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import DeleteIcon from '@mui/icons-material/Delete';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import PrintIcon from '@mui/icons-material/Print';
import { Card, CardContent } from '@mui/material';
import { TextField } from '@mui/material';
import dayjs from 'dayjs';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import Button from '@mui/material/Button';
import ReReport from '../../../components/Report/ReReport';
import ReCoppyReport from '../../../components/Report/ReCoppyReport';
import { ViewTitle } from 'components/ViewTitle';
import { useHistory } from 'react-router';
import { Loading } from 'components/Loading';
import ReUniReport from '../../../components/Report/ReUniReport';
import ReUniCopy from '../../../components/Report/ReUniCopy';
import ReReportNormal from '../../../components/Report/ReReportNormal';
import ReCopyNormal from '../../../components/Report/ReCopyNormal';

function Re({ title, subtitle }) {
  const dispatch = useDispatch();
  dayjs.extend(buddhistEra);
  const history = useHistory();
  const information = useSelector((state) => state.information);
  const receipt = useSelector((state) => state.receipt);
  const [findName, setFindName] = useState('');
  const [page, setPage] = React.useState(1);
  const [size, setSize] = React.useState(5);
  const [selectQuotation, setSelectQuotation] = useState();

  useEffect(() => {
    dispatch(action.getAllInformation());
    dispatch(action.getAllReceipt({ name: findName, page, size }));
    return () => {};
  }, [findName, page, size]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handlePrintReReport = async (data) => {
    try {
      await ReReport(data, information);
    } catch (error) {
      alert('ระบบปริ้นมีปัญหา โปรดแจ้งแอดมิน');
    }
  };
  const handlePrintReReportUni = (data) => {
    ReUniReport(data, information);
  };
  const handlePrintReReportNormal = (data) => {
    ReReportNormal(data, information);
  };
  const handlePrintCoppyReReport = async (data) => {
    try {
      await ReCoppyReport(data, information);
    } catch (error) {
      alert('ระบบปริ้นมีปัญหา โปรดแจ้งแอดมิน');
    }
  };
  const handlePrintUniReReport = async (data) => {
    try {
      await ReUniCopy(data, information);
    } catch (error) {
      alert('ระบบปริ้นมีปัญหา โปรดแจ้งแอดมิน');
    }
  };
  const handlePrintReCopyNormal = (data) => {
    ReCopyNormal(data, information);
  };

  const handleChangeRowsPerPage = (event) => {
    setSize(event.target.value);
    setPage(1);
  };
  const DeleteProduct = async (id) => {
    const confirm = window.confirm('ยืนยันการลบข้อมูล');
    if (confirm) {
      try {
        await dispatch(action.deleteReceipt(id));
        await dispatch(action.getAllReceipt({ findName, page, size }));
      } catch (error) {
        console.error(error);
      }
    }
  };

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;
  const renderAddButton = () => (
    <Button
      variant="contained"
      onClick={() => history.push('/sale/newreceipt')}
    >
      เพิ่ม
    </Button>
  );

  if (receipt?.isLoading) {
    return (
      <div>
        <Loading isLoading />
      </div>
    );
  }

  return (
    <div>
      <div className="flex justify-between">
        {renderTitle()}
        <div className="py-4">{renderAddButton()}</div>
      </div>
      <Card className="mb-2">
        <CardContent>
          <div>
            <div className="flex">
              <TextField
                id="outlined-basic"
                label="ค้นหา"
                variant="outlined"
                size="small"
                onChange={(e) => setFindName(e.target.value)}
                fullWidth
              />
            </div>
          </div>
        </CardContent>
      </Card>
      <Paper>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <p className="font-bold">เลขที่</p>
                </TableCell>
                <TableCell>
                  {' '}
                  <p className="font-bold">วันที่</p>
                </TableCell>
                <TableCell>
                  {' '}
                  <p className="font-bold">ชื่อลูกค้า</p>
                </TableCell>
                <TableCell>
                  {' '}
                  <p className="font-bold">เบอร์โทร</p>
                </TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {receipt.arr?.map((receipts) => (
                <TableRow key={receipts._id}>
                  <TableCell component="th" scope="row">
                    <Link to={`/sale/editreceipt/${receipts?._id}`}>
                      {receipts.prefix + receipts.running_number}{' '}
                    </Link>
                  </TableCell>
                  <TableCell>
                    <Link to={`/sale/editreceipt/${receipts?._id}`}>
                      {dayjs(receipts.date).format('DD/MM/BB')}
                    </Link>{' '}
                  </TableCell>
                  <TableCell>
                    <Link to={`/sale/editreceipt/${receipts?._id}`}>
                      {receipts.customer?.businessname}
                    </Link>{' '}
                  </TableCell>
                  <TableCell>{receipts.customer?.tel}</TableCell>
                  <TableCell align="right">
                    <Stack spacing={1} direction="row" className="justify-end">
                      <Button
                        variant="contained"
                        color="success"
                        size="small"
                        startIcon={<PrintIcon size="small" />}
                        onClick={() => {
                          // console.log("print", receipts);
                          if (receipts?.vatNumber === 0) {
                            handlePrintReReportNormal(receipts);
                          } else {
                            handlePrintReReport(receipts);
                          }
                        }}
                      >
                        พิมพ์
                      </Button>
                      <Button
                        variant="contained"
                        color="warning"
                        size="small"
                        onClick={() => {
                          // console.log("print", receipts);
                          if (receipts?.vatNumber === 0) {
                            handlePrintReCopyNormal(receipts);
                          } else {
                            handlePrintCoppyReReport(receipts);
                          }
                        }}
                      >
                        พิมพ์สำเนา
                      </Button>

                      <Button
                        color="error"
                        variant="contained"
                        size="small"
                        startIcon={<DeleteIcon size="small" />}
                        onClick={() => {
                          DeleteProduct(receipts?._id);
                        }}
                      >
                        ลบ
                      </Button>
                    </Stack>
                  </TableCell>{' '}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10]}
          component="div"
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          count={receipt?.total || 0}
          rowsPerPage={size}
          page={page - 1}
        />
      </Paper>
    </div>
  );
}
export default Re;
