import _ from 'lodash';

export default function SumDiscount(productList) {
  if (productList?.length === 0) {
    return 0;
  } else {
    const sumP = _.sumBy(productList, (each) => parseInt(each?.discount));
    return isNaN(sumP) ? 0 : sumP;
  }
}
